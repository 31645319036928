import * as React from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectHeading,
  CPostCard02,
  LPlan,
  CJumbotron02,
} from '../../components/_index';
import planChoice from "../../utils/plan-choice"
import planGet from "../../utils/plan-get"

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          title: {
            en: 'oiwai',
            ja: 'お祝い',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/oiwai/kv.png',
              },
              imgSp: {
                src: '/assets/images/oiwai/kv_sp.png',
              },
            },
            {
              img: {
                src: '/assets/images/oiwai/kv02.png',
              },
              imgSp: {
                src: '/assets/images/oiwai/kv02_sp.png',
              },
            },
            {
              img: {
                src: '/assets/images/oiwai/kv03.png',
              },
              imgSp: {
                src: '/assets/images/oiwai/kv03__sp.png',
              },
            },
            {
              img: {
                src: '/assets/images/oiwai/kv04.png',
              },
              imgSp: {
                src: '/assets/images/oiwai/kv04__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [],
            current: {
              label: 'お祝い',
            },
          }}
        />
      </CJumbotron>
      <div className="l_sect">
        <section className="u_mbLarge">
          <LWrap>
            <h2 className="c_headingLv2">
              リゾートホテルならでは
              <br />
              ちょっと贅沢なお祝い
            </h2>
            <p className="c_sectLead u_mbMedium">
              仙台駅から車で約30分とは思えない心が落ち着く自然溢れる美しい景色とヨーロッパの趣を感じる当ホテル。
              <br />
              豊かな自然の中でゆったり過ごす贅沢なプランです。
            </p>
          </LWrap>
        </section>
        <section id="annivasary" className="u_mbLarge">
          <div>
            <CSectHeading
              data={{
                img: {
                  src: '/assets/images/oiwai/img_heading.png',
                },
                imgSp: {
                  src: '/assets/images/oiwai/img_heading__sp.png',
                },
                title: (
                  <>
                    <p className="en">ANNIVASARY</p>
                    大切な人と大切な日を
                    <br className="u_sp" />
                    祝うアニバーサリー
                  </>
                ),
                text: (
                  <>
                    喧騒を忘れるリゾートでゆっくり記念日を満喫。
                    <br className="u_pc" />
                    大切なパートナーや家族との絆を深める特別なアニバーサリーをご体験ください。
                  </>
                ),
              }}
              exClass="oiwai"
            />
            <p className="sp_text">
              喧騒を忘れるリゾートでゆっくり記念日を満喫。
              <br className="u_pc" />
              大切なパートナーや家族との絆を深める特別なアニバーサリーをご体験ください。
            </p>
          </div>
        </section>
        <section className="u_mbLarge">
          <LWrap>
            <CPostCard02 data={planChoice(planGet(), ['お祝い'], 0)} />
          </LWrap>
        </section>
        <section id="propose" className="u_mbSmallLarge">
          <div>
            <CSectHeading
              data={{
                img: {
                  src: '/assets/images/oiwai/img_heading02.png',
                },
                imgSp: {
                  src: '/assets/images/oiwai/img_heading02__sp.png',
                },
                title: (
                  <>
                    <p className="en">PROPOSE</p>
                    大切な人に想いを伝える
                    <br className="u_sp" />
                    プロポーズ
                  </>
                ),
                text: (
                  <>
                    喧騒を忘れるリゾートでゆっくり記念日を満喫。
                    <br className="u_pc" />
                    大切なパートナーや家族との絆を深める特別なアニバーサリーをご体験ください。
                  </>
                ),
              }}
            />
            <p className="sp_text">
              喧騒を忘れるリゾートでゆっくり記念日を満喫。
              <br className="u_pc" />
              大切なパートナーや家族との絆を深める特別なアニバーサリーをご体験ください。
            </p>
          </div>
        </section>
        <section className="u_mbLarge">
          <LWrap>
            <CPostCard02 data={planChoice(planGet(), ['プロポーズ'], 0)} />
          </LWrap>
        </section>
      </div>
      <LPlan />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
